/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';

import Content from 'components/pages/blog-post/content';
import CTA from 'components/shared/cta';
import { defaultCTA } from 'constants/component-data';
import MainLayout from 'layouts/main';
import getReactContentWithLazyBlocks from 'utils/get-react-content-with-lazy-blocks';

const BlogPost = ({
  data: {
    wpPost: {
      seo,
      title,
      date,
      url,
      content,
      blogPostFields: {
        author: { title: authorName, postAuthor },
      },
    },
  },
  pageContext,
}) => {
  const { reactedContent } = getReactContentWithLazyBlocks(content);

  return (
    <MainLayout seo={seo} pageContext={pageContext} headerTheme="primary-white">
      <Content
        title={title}
        date={date}
        authorAvatar={postAuthor?.avatar}
        authorName={authorName}
        url={url}
        content={reactedContent}
      />
      <CTA {...defaultCTA} />
    </MainLayout>
  );
};

export default BlogPost;

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      date(formatString: "MMMM DD, yyyy")
      content
      url: uri
      blogPostFields {
        author {
          ... on WpPostAuthor {
            title
            postAuthor {
              avatar {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 100, quality: 100, formats: [PNG])
                  }
                }
              }
            }
          }
        }
      }
      ...wpPostSeo
    }
  }
`;
