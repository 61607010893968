import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import SocialShare from '../social-share';

const Content = ({ title, authorAvatar, authorName, date, content, url }) => (
  <article className="mt-20">
    <Container size="sm">
      <Heading tag="h1" theme="primary-black" size="xl">
        {title}
      </Heading>
      <div className="flex items-center space-x-1.5 mt-5">
        {authorAvatar && (
          <GatsbyImage
            className="w-7 h-7 rounded-full"
            imgClassName="rounded-full"
            image={getImage(authorAvatar.localFile)}
            alt={authorName}
          />
        )}
        <span>{authorName}</span>
        <span className="inline-flex w-1 h-1 bg-primary-black rounded-full" />
        <span className="text-grey-60">{date}</span>
      </div>
      <div className="prose prose-lg border-t-2 border-dotted pt-11 md:pt-6 !mt-11 md:!mt-6 border-grey-30 prose-table:!mx-0 prose-table:!w-[initial]">
        {content}
      </div>
      <SocialShare className="flex items-center flex-col mt-16" title={title} url={url} />
    </Container>
  </article>
);

Content.propTypes = {
  title: PropTypes.string.isRequired,
  authorAvatar: PropTypes.shape({
    localFile: PropTypes.shape({}),
  }).isRequired,
  authorName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
};

export default Content;
