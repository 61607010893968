import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { LinkedinShareButton, TwitterShareButton, FacebookShareButton } from 'react-share';

import FacebookIcon from './images/facebook.inline.svg';
import LinkedInIcon from './images/linkedin.inline.svg';
import TwitterIcon from './images/twitter.inline.svg';

const SocialShare = ({ className, title, url }) => {
  const postUrl = `${process.env.GATSBY_DEFAULT_SITE_URL}${url}`;

  return (
    <div className={classNames(className)}>
      <span className="text-lg">Share this article</span>
      <div className="flex space-x-5 mt-5">
        <TwitterShareButton url={postUrl} title={title} via="browserless">
          <TwitterIcon className="text-grey-20 hover:text-[#1DA1F2] duration-200 transition-colors" />
        </TwitterShareButton>
        <LinkedinShareButton url={postUrl} title={title}>
          <LinkedInIcon className="text-grey-20 hover:text-[#0A66C2] duration-200 transition-colors" />
        </LinkedinShareButton>
        <FacebookShareButton url={postUrl}>
          <FacebookIcon className="text-grey-20 hover:text-[#1877F2] duration-200 transition-colors" />
        </FacebookShareButton>
      </div>
    </div>
  );
};

SocialShare.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

SocialShare.defaultProps = {
  className: null,
};

export default SocialShare;
